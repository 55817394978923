<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：账户管理</strong>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :lg="24">
                    <el-form @submit.native.prevent="getAccountList()">
                        <el-row>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="$refs.Account.isTable = true,$refs.Account.addAccount()">新增添加
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="$refs.Account.isTable = true,$refs.Account.accountForm.nickNameList ='',$refs.Account.dialogBatch = true,$refs.Account.dialogBatchUpdate=false,$refs.Account.accountForm.isBatchSaveType=true">批量添加
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="$refs.Account.isTable = true,$refs.Account.doBatchUpdteEvent()">批量修改角色
                                </el-button>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="查询:">
                                    <el-input size="mini" style="width: 180px" v-model="tablePage.keyword" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini"
                                           @click="getAccountList()">搜索查询
                                </el-button>
                            </el-col>
                            <el-col class="pull-left" :lg="4">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>

            <el-row>
                <el-col :lg="24">
                    <vxe-grid
                            border
                            resizable
                            keep-source
                            ref="xGrid"
                            :height="(this.getViewHeight() - 130)+''"
                            :export-config="{type: ['xlsx'], types: ['xlsx']}"
                            highlight-hover-row
                            :data="tableDataMain"
                            :checkbox-config="{labelField:'id',range:true}"
                            :columns="tableColumn">
                        <template #operate="{row}">
                            <template>
                                <vxe-button icon="el-icon-edit-outline" title="编辑" circle
                                            @click="$refs.Account.isTable = true,$refs.Account.editRowEvent(row.id)"></vxe-button>
                            </template>
                            <vxe-button icon="el-icon-delete" title="删除" circle @click="removeEvent(row)"></vxe-button>
                        </template>
                        <template #pager>
                            <vxe-pager
                                    :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                    :current-page.sync="tablePage.currentPage"
                                    :page-size.sync="tablePage.pageSize"
                                    :total="tablePage.total"
                                    @page-change="handlePageChange">
                            </vxe-pager>
                        </template>
                    </vxe-grid>
                </el-col>
            </el-row>
        </el-main>
        <Account ref="Account"></Account>
    </div>
</template>

<script>

    import Account from "../../components/Account";
    export default {
        components: {Account},
        data() {
            return {
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    keyword: ''
               },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'checkbox', title: '全选', width: 60},
                    {
                        field:'nick_name', width: 60, title: '姓名'
                   },
                    {field:'user_name', width: 140, title: '登录账号(手机号码)'},
                    {field:'created', width: 180, title: '创建日期'},
                    {field:'updated', width: 180, title: '更新日期'},
                    {field:'role_name', width: 180, title: '角色'},
                    {field:'billing_company_name', width: 280, title: '默认开票公司'},
                    {field:'shortcut_key', width: 120, title: '快捷键',},
                    {
                        field:'state',
                        title: '启用',
                        width: 60,
                        cellRender: {
                            name: 'ElSwitch',
                            props: {'active-value': 1, 'inactive-value': 0},
                            events: {change: this.changeSwitch}
                       }
                    },
                    {
                        field:'is_show_company_name',
                        title: '显示客户名字',
                        width: 60,
                        titleHelp: {message: '关闭，'},
                        cellRender: {
                            name: 'ElSwitch',
                            props: {'active-value': true, 'inactive-value': false},
                            events: {change: this.changeCompanyNameSwitch}
                        }
                    },
                    {field:'operate',title: '操作', width: 120, slots: {default: 'operate'}}
                ],
                tableDataMain: [],
           }
       },
        methods: {
            removeEvent(row) {
                this.$XModal.confirm('您确定要删除"' + row.nick_name + '"该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/account/deleteVue',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                //$table.remove(row)
                                /*this.dialogFormVisible = false;
                                */
                                this.getAccountList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               })
           },
            changeSwitch({row}, $event) {
                row.state = row.state == 0 ? 1 : 0
                this.$confirm('确定要改变账户状态 : ' + row.nick_name + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
               }).then(() => {
                    //发送ajax请求，处理
                    this.$axios({
                        method: 'post',
                        url: '/admin/account/switchStateVue',
                        params: {
                            id: row.id,
                            checked: $event == 1 ? true : false
                       }
                   }).then((response) => {         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.data.state === 'ok') {
                            this.$message({
                                showClose: true,
                                message: '修改成功',
                                type: 'success'
                           });
                            row.state = row.state == 0 ? 1 : 0
                       } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }
                   }).catch((error) => {
                        console.log(error)
                   });

               }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                   });
               });
           },
            changeCompanyNameSwitch({row}, $event) {
                row.is_show_company_name = row.is_show_company_name == true ? false : true
                this.$confirm('修改显示客户名: ' + row.nick_name + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //发送ajax请求，处理
                    this.$axios({
                        method: 'post',
                        url: '/admin/account/changeCompanyNameSwitch',
                        params: {
                            id: row.id,
                            checked: $event == true ? true : false
                        }
                    }).then((response) => {         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.data.state === 'ok') {
                            this.$message({
                                showClose: true,
                                message: '修改成功',
                                type: 'success'
                            });
                            row.is_show_company_name = row.is_show_company_name == true ? false : true
                        } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                            });
                            return
                        }
                    }).catch((error) => {
                        console.log(error)
                    });

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAccountList();
           },
            //查询accountList
            async getAccountList() {
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountList',
                    params: this.tablePage
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
       },
        created() {
            this.getAccountList();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
            })
       }
   };
</script>

<style scoped>

</style>
